import * as React from 'react';
import styled from '@caffedpkg/microstyled';

const SingleColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
`;

const Row = styled.div`
  position: relative;
  margin: 10px 0;
`;

const Title = styled.h1`
  font-size: 32px;
  font-weight: 400;
  margin: 0 auto;
  padding: 0;
`;

const StyledLink = styled.a`
  color: white;
  &:not(:last-child) {
    margin-right: 12px;
  }
`;

interface LinkProps extends React.PropsWithChildren {
  href: string;
  label: string;
}

const Link: React.FC<LinkProps> = (props: LinkProps): JSX.Element => {
  const { children, href, label, ...restProps } = props;
  return <StyledLink
            {...restProps}
            href={href}
            aria-label={label}
            title={label}>
            {children}
          </StyledLink>;
};

const Description = () => {
  return (
    <SingleColumn>
      <Row>
        <Title
         id="name" 
         aria-label="Carlo L Mogavero"
        >
          CARLO L MOGAVERO
        </Title>
      </Row>
      <Row>
        <div aria-label='I am a generalist/fullstack developer with over 20 years of experience.'>
          <span>
            I am a generalist/fullstack developer with over 20 years of experience.
          </span>
        </div>
      </Row>
      <Row>
        <div aria-label="Links" >
          <Link href="https://github.com/caffed" label="Github Link">
            <span dangerouslySetInnerHTML={{__html: `<svg aria-hidden="true" className="octicon octicon-mark-github" height="28" role="img" version="1.1" viewBox="0 0 16 16" width="28"><path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59 0.4 0.07 0.55-0.17 0.55-0.38 0-0.19-0.01-0.82-0.01-1.49-2.01 0.37-2.53-0.49-2.69-0.94-0.09-0.23-0.48-0.94-0.82-1.13-0.28-0.15-0.68-0.52-0.01-0.53 0.63-0.01 1.08 0.58 1.23 0.82 0.72 1.21 1.87 0.87 2.33 0.66 0.07-0.52 0.28-0.87 0.51-1.07-1.78-0.2-3.64-0.89-3.64-3.95 0-0.87 0.31-1.59 0.82-2.15-0.08-0.2-0.36-1.02 0.08-2.12 0 0 0.67-0.21 2.2 0.82 0.64-0.18 1.32-0.27 2-0.27 0.68 0 1.36 0.09 2 0.27 1.53-1.04 2.2-0.82 2.2-0.82 0.44 1.1 0.16 1.92 0.08 2.12 0.51 0.56 0.82 1.27 0.82 2.15 0 3.07-1.87 3.75-3.65 3.95 0.29 0.25 0.54 0.73 0.54 1.48 0 1.07-0.01 1.93-0.01 2.2 0 0.21 0.15 0.46 0.55 0.38C13.71 14.53 16 11.53 16 8 16 3.58 12.42 0 8 0z"></path></svg>` }}/>
          </Link>
          <Link href="https://twitter.com/zixel" label="Twitter Link">
            <span dangerouslySetInnerHTML={{__html: `<svg xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" id="svg2" xml:space="preserve" height="28.12" width="34.26" version="1.1" viewBox="0 0 182.66667 150.66667"><metadata id="metadata8"><rdf:rdf><cc:work rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage"></dc:type></cc:work></rdf:rdf></metadata><defs id="defs6"><clipPath id="clipPath20" clipPathUnits="userSpaceOnUse"><path id="path18" d="m0 10.012h1366.9v1110.9h-1366.9z"></path></clipPath></defs><g id="g10" transform="matrix(1.3333 0 0 -1.3333 0 150.67)"><g id="g12" transform="scale(.1)"><g id="g14"><g id="g16" clip-path="url(#clipPath20)"><path id="path22" d="m1366.9 989.39c-50.27-22.309-104.33-37.387-161.05-44.18 57.89 34.723 102.34 89.679 123.28 155.15-54.18-32.15-114.18-55.47-178.09-68.04-51.13 54.49-124.02 88.55-204.68 88.55-154.89 0-280.43-125.55-280.43-280.43 0-21.988 2.457-43.398 7.258-63.91-233.08 11.68-439.72 123.36-578.04 293.01-24.141-41.4-37.969-89.567-37.969-140.97 0-97.308 49.489-183.13 124.76-233.44-45.969 1.437-89.218 14.058-127.03 35.078-0.043-1.18-0.043-2.348-0.043-3.52 0-135.9 96.68-249.22 224.96-275-23.512-6.41-48.281-9.8-73.86-9.8-18.089 0-35.628 1.711-52.781 5 35.711-111.41 139.26-192.5 262-194.77-96.058-75.23-216.96-120.04-348.36-120.04-22.621 0-44.961 1.332-66.918 3.91 124.16-79.568 271.55-125.98 429.94-125.98 515.82 0 797.86 427.31 797.86 797.93 0 12.153-0.28 24.223-0.79 36.25 54.77 39.571 102.31 88.95 139.93 145.2"></path></g></g></g></g></svg>` }} />
          </Link>
          <Link href="https://www.linkedin.com/pub/carlo-mogavero/15/977/51a" label="LinkedIn Link">
            <span dangerouslySetInnerHTML={{__html: `<svg xmlns="http://www.w3.org/2000/svg" height="28" width="28" version="1" viewBox="0 0 1000 1000"><path d="M195.877 .25c-107.717 0 -195.877 88.158 -195.877 195.877l0 607.841c0 107.717 88.158 195.845 195.877 195.845l607.841 0c107.718 0 195.845 -88.127 195.845 -195.845l0 -607.841c0 -107.717 -88.127 -195.877 -195.845 -195.877l-607.841 0zm49.266 164.948c51.648 0 83.461 33.906 84.443 78.475 0 43.585 -32.797 78.444 -85.442 78.444l-.969 0c-50.665 0 -83.412 -34.857 -83.412 -78.444 0 -44.568 33.738 -78.475 85.379 -78.475zm445.08 208.31c99.329 0 173.79 64.922 173.79 204.436l0 260.449l-150.953 0l0 -242.987c0 -61.06 -21.847 -102.718 -76.476 -102.718 -41.704 0 -66.562 28.078 -77.476 55.202 -3.987 9.704 -4.967 23.257 -4.967 36.832l0 253.671l-150.953 0s1.981 -411.613 0 -454.233l150.984 0l0 64.324c20.06 -30.95 55.942 -74.977 136.051 -74.977zm-521.556 10.685l150.953 0l0 454.202l-150.953 0l0 -454.202z"></path></svg>` }} />
          </Link>
        </div>
      </Row>
      <Row>
        <div>
          <span>Resume: <Link href='/carlo.mogavero.pdf' label='PDF Resume Link'>PDF</Link> | <Link href='/carlo.mogavero.html' label='HTML Resume Link'>HTML</Link></span>
        </div>
      </Row>
    </SingleColumn>
  );
};

export default Description;
