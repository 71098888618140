import React, { useEffect, useRef /*, useState */ } from 'react';
import { 
  OrthographicCamera,
  PerspectiveCamera,
  Scene,
  Renderer,
} from 'three';
import styled from '@caffedpkg/microstyled';
import { Six } from './types';
import { randomString } from './utils';

// TODO: what is the best type abstraction?
export type ThreeCamera = OrthographicCamera | PerspectiveCamera;

interface ThreeProps extends Partial<Six.GenericComponent> {
  scene: Scene;
  camera: ThreeCamera;
  renderer: Renderer;
  width: number;
  height: number;
  frustumSize?: number;
  devicePixelRatio: number;
  // el?: HTMLDivElement | null;
  hooks?: Six.LifecycleHooks;
  intervalTimings?: Partial<Six.RAFTimings>;
}

const ThreeContainer = styled.div`
  position: relative;
  top: 0px;
  bottom: 0px;
`;

const ThreeChildrenContainer = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
`;

// ThreeJS Rendering Context Component
export const ThreeComponent = (props: ThreeProps): JSX.Element => {
  // Context
  const context: Six.AnyObject = {};

  // TODO: see if this helps https://r105.threejsfundamentals.org/threejs/lessons/threejs-offscreencanvas.html
  //  renderOffScreen: boolean;
  //  offScreenCanvas: HTMLCanvasElement;
  //  offScreenWorker: Worker;
  const {
    camera,
    children,
    devicePixelRatio,    
    frustumSize = 30,
    height,
    hooks,
    intervalTimings,
    renderer,
    scene,
    width,
  } = props;
  context.camera = camera;
  context.children = children;
  context.devicePixelRatio = devicePixelRatio;
  // default frustum val for orhto and persp?
  context.frustumSize = frustumSize;
  context.height = height;
  context.hooks = hooks;
  context.intervalTimings = {
    interval: intervalTimings.interval || 1000 / intervalTimings.rps,
    rps: intervalTimings.rps || 20,
    then: Date.now(),
  };
  context.renderer = renderer;
  context.scene = scene;
  context.width = width;

  // TODO: enable offscreen renderer for Chrome
  // if ('transferControlToOffscreen' in renderer.domElement) {
  //   context.renderOffScreen = true;
  //   // @ts-ignore
  //   context.offScreenCanvas = document.createElement('canvas'); // renderer.domElement.transferControlToOffscreen();
  //   context.offScreenWorker = new Worker(makeScriptWithURL(offscreenHandlerScript), { type: 'module' } );
  // }
  // const resizeObserver = new ResizeObserver((entries) => {
  //   for (let entry of entries) {
  //     if(entry.contentBoxSize) {
  //       console.log({ entry });
  //     } else {
  //       console.log({ entry });
  //     }
  //   }
  // });
  // resizeObserver.observe(renderer.domElement);

  // lifecycle and supporting functions
  context.animate = (): void => {
    context.hooks.preRAF?.(context);
    context.renderScene();
    context.frameId = window.requestAnimationFrame(context.animate);
    context.hooks.postRAF?.(context);
  }

  context.start = (): void => {
    context.animate();
  }

  context.stop = (): void => {
    window.cancelAnimationFrame(context.frameId)
  }

  context.handleOnMouseMove = (evt: any): void => {
    context.hooks.preMouseMove?.(evt, context);
    // core logic? if none, change to just: context.hooks.mouseMove?.(evt, context);
    context.hooks.postMouseMove?.(evt, context);
  }

  context.handleOnResize = (evt: any): void => {
    context.hooks.preResize?.(context);
    const { innerHeight, innerWidth } = evt.target;
    if (innerHeight && innerWidth) {
      context.width = innerWidth;
      context.height = innerHeight; 
    }
    if (context.hooks.resize) {
      context.hooks.resize(context);
    } else {
      context.updateFrustum();
    }
    context.hooks.postResize?.(context);
  }

  context.updateFrustum = (): void => {
    const { height, width, near, far } = context;
    // update code taken from  https://jsfiddle.net/a54eq9y3/1/
    const aspect = width / height;

    if ('left' in context.camera) { context.camera.left = context.frustumSize * aspect / - 2; }
    if ('right' in context.camera) { context.camera.right = context.frustumSize * aspect / 2; }
    // TODO: what should near and far defaults for borth types be?
    if ('near' in context.camera) { context.camera.near = near || 1; }
    if ('far' in context.camera) { context.camera.far = far || 1e3; }
    if ('aspect' in context.camera) { context.camera.aspect = aspect;}
    // Orthographic only
    if ('top' in context.camera) { context.camera.top = context.frustumSize / 2; }
    if ('bottom' in context.camera) { context.camera.bottom = - context.frustumSize / 2; }

    context.camera.updateProjectionMatrix();
    context.renderer.setSize(width, height);
  }

  context.renderScene = (): void =>{
    // if (context.renderOffScreen) {
    //   // @ts-ignore
    //   context.offScreenWorker.postMessage(
    //     {
    //       drawingSurface: context.offScreenCanvas,
    //       width: context.renderer.domElement.clientWidth,
    //       height: context.renderer.domElement.clientHeight,
    //       pixelRatio: window.devicePixelRatio,
    //     },
    //     [context.offScreenCanvas],
    //   );
    // } else {

      const now = Date.now();
      const delta = now - context.intervalTimings.then;
      if (delta > context.intervalTimings.interval) {
        context.hooks.preRender?.(context);
        if (context.hooks.render) {
          context.hooks.render(context);
        } else {
          context.renderer.render(context.scene, context.camera);
        }
        context.intervalTimings.then = now - (delta % context.intervalTimings.interval);
        context.hooks.postRender?.(context);
      }
    // }
  }
  const ref = useRef(null);
  // Finished compnent state init
  context.hooks.init?.(context);

  useEffect(() => {
    // componentDidMount
    context.hooks.preMount?.(context);
    if (ref.current) {
      ref.current.appendChild(context.renderer.domElement);
      if (context.eventHandlers) {
        context
          .eventHandlers
          .forEach((handler: Six.EventListener) => {
              ref.current.addEventListener(handler.name, handler.callback);
            });
      }
      ref.current.addEventListener('resize', context.handleOnResize);
      ref.current.addEventListener('mousemove', context.handleOnMouseMove);
    }
    const { height, width } = context;
    context.renderer.setSize(width, height);
    context.start();
    context.hooks.postMount?.(context);
    // componentWillUnmount
    return () => {
      context.stop();
      context.hooks.preUnmount?.(context);
      ref.current?.removeChild(context.renderer.domElement);
    };
  });

  // TODO: change to render props or similar and move container comps out
  //return <div className="three" ref={ref}>{props.children}</div>;
  return  (
    <ThreeContainer>
      <div className={`three-${randomString(10)}`} ref={ref}>
        {props.children && 
          <ThreeChildrenContainer>
            {props.children}
          </ThreeChildrenContainer>}
      </div>
    </ThreeContainer>
  );
};

export default ThreeComponent;
