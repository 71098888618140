import React from 'react';

// import Three from './Six/Three';
import Three from './Six/Three.Func';

import Project from './projects/waves/Waves';
import { devicePixelRatio, innerHeight, innerWidth } from './Config';
import { ThemeCacheProvider, ThemeProvider } from '@caffedpkg/microstyled';
import Content from './Content';
import CaffedTheme from './Theme';
import GlobalStyles from './GlobalStyles';

const StyleCache = ThemeCacheProvider(async () => document.head);
const Theme = ThemeProvider(CaffedTheme);

const App: React.FC = (): JSX.Element => {
  const { camera, scene, renderer, hooks } = new Project();
  return (
    <React.Suspense fallback={<>Loading...</>}>
      <StyleCache>
        <Theme>
          <GlobalStyles />
          <Three 
            devicePixelRatio={devicePixelRatio}
            camera={camera}
            scene={scene}
            renderer={renderer}
            hooks={hooks}
            width={innerWidth}
            height={innerHeight}
            intervalTimings={{rps:30}}
          >
            <Content />
          </Three>
        </Theme>
      </StyleCache>
    </React.Suspense>
  );
};

export default App;
