
import React from 'react';
import styled from '@caffedpkg/microstyled';

interface LayoutProps {
  portrait: React.ReactNode;
  resume: React.ReactNode;
  description: React.ReactNode;
}

const CenterJusitified = styled.div`
  max-width: 540px;
  padding: 16px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    & {
      max-width: 100%;
      padding: 8px;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
    }
  }
`;

const Col = styled.div` 
  display: flex;
  flex-direction: column;
  padding: 64px;
  border-radius: 24px;
  margin: 10px;
  background: linear-gradient(45deg, rgba(110,110,110,.4) 0%, rgba(96,96,96,.4) 35%, rgba(70,70,70,.4) 100%);
  box-shadow: 10px 10px 2px 1px rgba(75, 75, 75, 0.4);
    @media (max-width: 768px) {
      & {
        padding: 12px;
      }
    }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 92%;
  padding: 8px;
`;

export const Layout = (props: Partial<LayoutProps>): JSX.Element => {
  const { description, portrait } = props;
  
  return (
    <CenterJusitified>
      <Grid>
        <Col>
          <Row>
            { portrait }
          </Row>
          <Row>
            { description }
          </Row>
        </Col>
      </Grid>
    </CenterJusitified>
  );
};

export default Layout;
