import React from 'react';
import styled from '@caffedpkg/microstyled';
import portrait from './portrait.jpg';

const PortraitImg = styled.img`
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 40px;
`;

export const Portrait = (): JSX.Element => {
  return <PortraitImg alt="Portrait of Carlo Mogavero" src={portrait} />;
};

export default Portrait;
