// import type { MouseEvent } from 'react';
import autobind from 'autobind-decorator';

import THREE, {
  AmbientLight,
  Color,
  OrthographicCamera,
  Scene,
  ShaderMaterial,
  SpotLight,
  Vector2,
  Vector3,
  WebGLRenderer,
} from 'three';

// camera
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// postprocessing
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';

import { Six } from "../../Six";
import { throttle } from '../../Six/utils';

// scene object graph
import Model from '../../Model';
import { devicePixelRatio, innerWidth, innerHeight } from '../..//Config';

const vertexShader = `
#define GLSLIFY 1
// Texture varyings
varying vec2 v_uv;

/*
 * The main program
 */
void main() {
    // Calculate the varyings
    v_uv = uv;

    // Vertex shader output
    gl_Position = vec4(position, 1.0);
}

`;

const fragmentShader = `
#define GLSLIFY 1
// Common uniforms
uniform vec2 u_resolution;
uniform vec2 u_mouse;
// uniform float u_time;
uniform float u_frame;

// Texture uniforms
uniform sampler2D u_texture;

// Texture varyings
varying vec2 v_uv;

/*
 * The main program
 */
void main() {
	// Calculate the square size in pixel units based on the mouse position
	// float square_size = floor(2.0 + 30.0 * (u_mouse.x / u_resolution.x));

  float square_size = 3.0;

	// Calculate the square center and corners
	vec2 center = square_size * floor(v_uv * u_resolution / square_size) + square_size * vec2(0.5, 0.5);
	vec2 corner1 = center + square_size * vec2(-0.5, -0.5);
	vec2 corner2 = center + square_size * vec2(+0.5, -0.5);
	vec2 corner3 = center + square_size * vec2(+0.5, +0.5);
	vec2 corner4 = center + square_size * vec2(-0.5, +0.5);

	// Calculate the average pixel color
	vec3 pixel_color = 0.4 * texture2D(u_texture, center / u_resolution).rgb;
	pixel_color += 0.15 * texture2D(u_texture, corner1 / u_resolution).rgb;
	pixel_color += 0.15 * texture2D(u_texture, corner2 / u_resolution).rgb;
	pixel_color += 0.15 * texture2D(u_texture, corner3 / u_resolution).rgb;
	pixel_color += 0.15 * texture2D(u_texture, corner4 / u_resolution).rgb;

	// Fragment shader output
	gl_FragColor = vec4(pixel_color, 1.0);
}
`;

const uniforms = {
  u_frame : {
    type : "f",
    value : 0.0
  },
  u_resolution : {
    type : "v2",
    value : new Vector2(innerWidth, innerHeight).multiplyScalar(devicePixelRatio),
  },
  u_mouse : {
    type : "v2",
    value : new Vector2(0.5 * innerWidth, innerHeight).multiplyScalar(devicePixelRatio),
  },
  u_texture : {
    type: "t",
    // @ts-ignore
    value: null,
  }
};

@autobind
export class Waves implements Six.Three.Project {
  public scene: THREE.Scene; // TODO: need to figure out why THREE.Scene does not work with typings
  public camera: THREE.OrthographicCamera; // TODO: need to figure out why THREE.Camera does not work with typings
  public renderer: THREE.WebGLRenderer; // TODO: need to figure out why THREE.Renderer does not work with typings
  public composer: EffectComposer;
  public context: Six.AnyObject;

  constructor() {
    this.context = {};
    const aspect = innerWidth / innerHeight;
    const d = 20;

    this.camera = new OrthographicCamera(-d * aspect, d * aspect, d, -d, 1, 1000);
    this.scene = new Scene();
    this.renderer = new WebGLRenderer({
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: false,
    });

    // this.init();
  }

  public init(context: Six.AnyObject) : void {
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(innerWidth, innerHeight);
    this.renderer.setClearColor(new Color('rgb(130, 131, 126)'));
    // scene

    this.camera.rotation.order = 'XYZ';
    this.camera.rotation.y = -(Math.PI / 5); // -0.6530894089267631; //-Math.PI / 4;
    this.camera.rotation.x = 1; // 0.99855314097114; //  Math.atan(-1 / Math.sqrt(2));
    this.camera.rotation.z = -(Math.PI / 8.5); //-0.373075737314081; // -Math.PI / 4;
    this.camera.position.set(-100, 100, 215);
    this.camera.up = new Vector3(0, 0, 1);
    this.camera.lookAt(new Vector3(120, 80, 10));
    this.camera.zoom = 1.5;
    this.camera.updateProjectionMatrix()
    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.enablePan = false;

    // scene object graph
    const ambientLight = new AmbientLight(0x0c0c0c);
    ambientLight.intensity = 0.5;
    ambientLight.castShadow = true;
    ambientLight.color = new Color('rgb(255, 255, 255)');
    this.scene.add(ambientLight);
    // scene object graph

    const spotLight = new SpotLight(0xcccccc);
    spotLight.name = 'spohtlight';
    spotLight.intensity = 1;
    spotLight.position.set(-30, 60, 10);  
    spotLight.castShadow = true;
    this.scene.add(spotLight);

    // Initialize the effect composer
    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));

    const shader = new ShaderMaterial( {
      uniforms,
      vertexShader,
      fragmentShader,
    });
    // Add the post-processing effect
    const effect = new ShaderPass(shader, 'u_texture');
    effect.renderToScreen = true;
    this.composer.addPass(effect);
    
    this.scene.add(Model.mesh);

    window.addEventListener('mousemove', () => { this.renderComposer(); });
    window.addEventListener('touchmove', () => { this.renderComposer(); });
    window.addEventListener('resize', (evt: any) => {
      context.handleOnResize(evt);
      this.renderComposer();
    });
    window.addEventListener('load', () => {
      setInterval(() => {
        Model.hooks.preRender(context);
        this.renderComposer();
      }, 1500);
    });
  }

  public postResize (context: Six.AnyObject) {
    const { width, height, devicePixelRatio } = context;

    uniforms.u_resolution.value.set(width, height).multiplyScalar(devicePixelRatio);
    this.renderer.setSize(width, height);
    this.composer.setSize(width, height);
    if (context.updateProjectionMatrix) {
      context.updateProjectionMatrix();
    };
  }


  public preRender (context: Six.AnyObject) {
    Model.hooks.preRender(context);
  };

  public postRAF (context: Six.AnyObject) {
    context.stop();
  };

  public postMount (context: Six.AnyObject)  {
    Model.hooks.preRender(context);
    this.renderComposer();
  }

  // TODO: React + TS = cannot use correct generics
  // - need to find a way to specify (evt: MouseEvent) and a
  public preMouseMove (evt: Six.AnyObject, context: Six.AnyObject) {
    context.start();
    uniforms.u_mouse.value.set(evt.pageX, context.height - evt.pageY).multiplyScalar(context.devicePixelRatio);
  } 
  
  public renderComposer()  {
    // context.start();
    uniforms.u_frame.value += 1.0;
    this.composer.render();
    // context.stop();
  };

  public start() {}
  public stop() {}
  public render() {}

  public get exports() {
    return {
      camera: this.camera,
      renderer: this.renderer,
      scene: this.scene,
    };
  }

  public get hooks() {
    return {
      init: this.init,
      preMouseMove: throttle(this.preMouseMove, 200),
      postMount: this.postMount,
      postResize: this.postResize,
      preRender: this.preRender,
      postRAF: this.postRAF,
    }
  }

}

export default Waves;
