// main container component: Layout
import React from 'react';

import Layout from './Layout';
import Portrait from './Portrait';
import Description from './Description';
import Resume from './Resume';

export const Content = (): JSX.Element => {
  return <Layout 
    portrait={<Portrait />}
    description={<Description />}
    resume={<Resume />}
  />;
};

export default Content;
