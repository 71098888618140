import * as React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

const init = () => {
  const el = document.createElement('div');
  el.id = 'root-container';
  document.body.querySelector('main').appendChild(el);
  const root = createRoot(el);
  root.render(<App />);
};

window.addEventListener('DOMContentLoaded', init);
