// TODO: made extendable Model class from this
import {
  // BoxGeometry,
  Float32BufferAttribute,
  Mesh,
  // MeshNormalMaterial,
  Color,
  MeshStandardMaterial,
  Object3D,
  PlaneGeometry,
  // Vector3,
} from 'three';
import { ThreeHooks } from './Six/Three';
import SimplexNoise from './SimplexNoise';

interface Model<T extends Object3D> {
  mesh: T; 
  hooks: Readonly<ThreeHooks>;
}

const NOISE_STRENGTH = 1.5;//1.5;
const X_ZOOM = 6;
const Y_ZOOM = 18;
const SIDE_LENGTH = 120;
let OFFSET = Date.now() * 0.0004;
const simplex = new SimplexNoise();
const geometry = new PlaneGeometry(200, 200, SIDE_LENGTH, SIDE_LENGTH);
const material = new MeshStandardMaterial({
  roughness: 0.8,
  color: new Color('rgb(172, 172, 172)'),
  // wireframe: true,
});
const plane = new Mesh(geometry, material);
plane.castShadow = true;
plane.receiveShadow = true; 

const adjustVertices = () => {
  const positions = <Float32Array>plane.geometry.attributes.position.array;
  for (let i = 0; i < positions.length; i += 3) {
    const x = positions[i] / X_ZOOM;
    const y = positions[i + 1] / Y_ZOOM;
    const noise = simplex.noise2D(x, y + OFFSET) * NOISE_STRENGTH; 
    // // @ts-ignore
    positions[i + 2] = noise * 4;
  }
  plane.geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
  plane.geometry.computeBoundingBox();
}

// const adjustCameraPos = (context: any) => {  
//   let x = context.camera.position.x / X_ZOOM;
//   let y = context.camera.position.y / Y_ZOOM;
//   let noise = simplex.noise2D(x, y + OFFSET) * NOISE_STRENGTH + 1.5; 
//   context.camera.position.z = noise;
// }

const hooks: ThreeHooks = {
  preRender: (/* context */) => {
    // todo: find performant way to use offset value that is not a module global
    OFFSET = Date.now() * 0.0004;
    adjustVertices();
    // adjustCameraPos(context);
  }
}

const Landscape: Model<Mesh<PlaneGeometry, MeshStandardMaterial>> = {
  mesh: plane,
  hooks,
}

export default Landscape;
