// TODO: update microutils with these
export const randomString = (length = 20, {prefix = '', filter = false, filterRegex = /[0-9]/g} = {}): string => {
  let str = Math.random()
    .toString(36)
    .slice(2, 2 + length);
  // TODO: replace string.replace  with better Regexp filtering
  str = filter ? str.replace(filterRegex, '') : str;
  if (str.length !== length) {
    return randomString(length - str.length, { prefix: prefix.concat(str), filter, filterRegex });
  }
  return prefix.concat(str);
};

export const fakeUuid = (): string => {
  const fn = (num: number) => randomString(num, { filter: true, filterRegex: /[g-zG-Z]/g });
  return `${fn(8)}-${fn(4)}-${fn(4)}-${fn(4)}-${fn(12)}`;
};

export const debounce = (cb: Function, timeout = 300) => {
  let timer : ReturnType<typeof setTimeout>;
  return (...args: any) : void => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb(...args);
    }, timeout);
  };
}

export const throttle = (cb: Function, timeout = 300) => {
  let then : number = Date.now();
  return (...args: any) : void => {
    const now : number = Date.now();
    const delta : number = now - then;
    if (delta > timeout) {
      then = now - (delta % timeout);
      cb(...args);
    }
  };
}
